import {
  AccountBox,
  Event,
  SupervisedUserCircle,
  SystemSecurityUpdateGood,
} from "@mui/icons-material";
import { Menu, usePermissions } from "react-admin";

import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CampaignIcon from "@mui/icons-material/Campaign";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FeedIcon from "@mui/icons-material/Feed";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import GroupsIcon from "@mui/icons-material/Groups";
import { LogoutButton } from "./button";
import { NoAccounts } from "@mui/icons-material";
import { SideBarHeader } from "./header/sidemenu";
import SubMenu from "./subMenu";

export const CustomMenu = () => {
  const { permissions } = usePermissions();
  const slug = localStorage.getItem("slug") || "bondo";
  const tenant = JSON.parse(localStorage.getItem("tenant") || "");

  const isSiena = localStorage.getItem("schoolId") === "1282";

  const getAdminLabel = (role: string): string => {
    switch (role) {
      case "groupAdmin":
        return "Group Admin";
      case "clubAdmin":
        return "Club Admin";
      case "tenantAdmin":
        return "Tenant Admin";
      case "superAdmin":
        return "Super Admin";
      default:
        return "";
    }
  };

  return (
    <div className="flex flex-col justify-between h-[94vh]">
      <div>
        <SideBarHeader
          title={
            localStorage.getItem("userRole") === "superAdmin"
              ? "Super Admin"
              : isSiena
              ? "SIENA"
              : slug.toUpperCase()
          }
          text={localStorage.getItem("userRole") === "superAdmin" ? "" : getAdminLabel(permissions)}
        />
        <Menu>
          <Menu.Item to="/" primaryText="Dashboard" key="dashboard" leftIcon={<DashboardIcon />} />,
          {["tenantAdmin", "superAdmin"].some((p) => p === permissions) && [
            <Menu.Item
              to="/users"
              primaryText="Manage Users"
              key="manage-users"
              leftIcon={<AccountBox />}
            />,
          ]}
          {tenant?.topbarScreens.includes("groups") &&
            !isSiena &&
            ["groupAdmin", "tenantAdmin", "superAdmin"].some((p) => p === permissions) && [
              <Menu.Item
                key="manage-groups"
                to="/groups"
                primaryText="Manage Groups"
                leftIcon={<GroupsIcon />}
              />,
            ]}
          {tenant?.topbarScreens.includes("club") &&
            !isSiena &&
            ["clubAdmin", "tenantAdmin", "superAdmin"].some((p) => p === permissions) && [
              <Menu.Item
                key="manage-club"
                to="/clubs"
                primaryText="Manage Clubs"
                leftIcon={<SupervisedUserCircle />}
              />,
            ]}
          {tenant?.topbarScreens.includes("events") &&
            !isSiena &&
            ["clubAdmin", "tenantAdmin", "superAdmin"].some((p) => p === permissions) && [
              <Menu.Item
                key="manage-events"
                to="/events"
                primaryText="Manage Events"
                leftIcon={<Event />}
              />,
            ]}
          {["groupAdmin", "tenantAdmin", "superAdmin"].some((p) => p === permissions) && [
            <SubMenu
              primaryText="Manage Content"
              key="manage-content"
              leftIcon={<SystemSecurityUpdateGood />}>
              {["groupAdmin", "tenantAdmin", "superAdmin"].some((p) => p === permissions) &&
                !isSiena && [
                  <Menu.Item
                    key="manage-reported-posts"
                    to="report/admin/posts"
                    primaryText="Reported Posts"
                    leftIcon={<FeedIcon />}
                  />,
                ]}
              {["tenantAdmin", "superAdmin"].some((p) => p === permissions) && [
                <Menu.Item
                  key="manage-reported-profile"
                  to="report/admin/profiles"
                  primaryText="Reported Profiles"
                  leftIcon={<AssignmentIndIcon />}
                />,
              ]}
              {["tenantAdmin", "superAdmin"].some((p) => p === permissions) &&
                !isSiena && [
                  <Menu.Item
                    key="manage-reported-groups"
                    to="report/admin/groups"
                    primaryText="Reported Groups"
                    leftIcon={<GroupWorkIcon />}
                  />,
                ]}
            </SubMenu>,
          ]}
          {["tenantAdmin", "superAdmin"].some((p) => p === permissions) && [
            <Menu.Item
              key="bannedUsers"
              to="bannedUsers"
              primaryText="Banned Users"
              leftIcon={<NoAccounts fontSize="small" />}
            />,
          ]}
        </Menu>
      </div>
      <div>
        {["superAdmin"].some((p) => p === permissions) && [
          <Menu.Item
            key="broadcast"
            to="adminTools/broadcast"
            primaryText="Message Broadcast"
            leftIcon={<CampaignIcon />}
          />,
        ]}
        <LogoutButton />
        {
          // <Menu.Item to="/help" primaryText="Help" leftIcon={<HelpOutline />} />
        }
      </div>
    </div>
  );
};
