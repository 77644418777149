import { UserEmailInterface, UserLoginInterface, UserTokenInterface } from "./type/user";

import { AuthProvider } from "react-admin";
import { UserRequest } from "./api/user";
import { decodeToken } from "./helper/auth";

export const authProvider: AuthProvider = {
  login: async ({ email, password }) => {
    try {
      const data: UserLoginInterface = {
        email,
        password,
      };

      const { userData: response } = await UserRequest.login(data);
      if (!response.token) {
        throw new Error("No token found");
      }
      const user: UserTokenInterface = decodeToken(response.token);
      localStorage.setItem("tenant", JSON.stringify(response.tenant));
      localStorage.setItem("slug", response.slug);
      localStorage.setItem("token", response.token);
      localStorage.setItem("username", user.email);
      localStorage.setItem("userFullName", user.name);
      localStorage.setItem("userId", user.id.toString());
      localStorage.setItem("userRole", user.role);
      localStorage.setItem("schoolId", user.schoolId.toString());
      localStorage.setItem("userImg", user.imageUrl);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  Ssologin: async ({ email }: { email: string }) => {
    try {
      const data: UserEmailInterface = { email };
      const { userData: response } = await UserRequest.Ssologin(data);
      if (!response.token) {
        throw new Error("No token found");
      }
      const user: UserTokenInterface = decodeToken(response.token);
      console.log("user", user);
      localStorage.setItem("slug", response.slug);
      localStorage.setItem("token", response.token);
      localStorage.setItem("username", user.email);
      localStorage.setItem("userId", user.id.toString());
      localStorage.setItem("userRole", user.role);
      return Promise.resolve();
    } catch (error) {
      console.log("SSO login error", error);
      return error;
    }
  },
  logout: () => {
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("userRole");

    return Promise.resolve();
  },
  checkAuth: () => {
    const username = localStorage.getItem("username");
    return username ? Promise.resolve() : Promise.reject();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("userId");
      localStorage.removeItem("userRole");
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  getIdentity: () => {
    return Promise.resolve({
      id: localStorage.getItem("userId") || "",
      fullName: localStorage.getItem("userFullName") || "",
      userRole: localStorage.getItem("userRole") || "",
      avatar: localStorage.getItem("userImg") || "",
    });
  },
  getPermissions: () => {
    const role = localStorage.getItem("userRole");
    return role ? Promise.resolve(role) : Promise.reject();
  },
};
